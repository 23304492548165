// マイページの参画案件
const initializeSwiper = () => {
  new Swiper('#slide_work', {
    slidesPerView: 2,
    navigation: {
      nextEl: '.work_arr_next',
      prevEl: '.work_arr_prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      767: {
        slidesPerView: 4,
      },
    },
  })
}

window.addEventListener('DOMContentLoaded', initializeSwiper)
