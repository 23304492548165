if (document.URL.match(/new/) || document.URL.match(/edit/)) {
  document.addEventListener('DOMContentLoaded', () => {
    const pcImage = document.querySelector('#carousel_pc_image')
    if (pcImage != null) {
      pcImage.addEventListener('change', (e) => {
        const imageContent = document.querySelector('#pc-img')
        const file = e.target.files[0]
        const blob = window.URL.createObjectURL(file)
        imageContent.src = blob
      })
    }
  })

  document.addEventListener('DOMContentLoaded', () => {
    const spImage = document.querySelector('#carousel_sp_image')
    if (spImage != null) {
      spImage.addEventListener('change', (e) => {
        const imageContent = document.querySelector('#sp-img')
        const file = e.target.files[0]
        const blob = window.URL.createObjectURL(file)
        imageContent.src = blob
      })
    }
  })
}
