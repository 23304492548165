// Activity Index point information
function spPointDisplay() {
  const spDisplayPoint = document.querySelector('#sp-display-point-section')
  const spHiddenPoint = document.querySelector('#sp-hidden-point-section')

  const spDisplayButton = document.querySelector('#sp-display-button')
  const spHiddenButton = document.querySelector('#sp-hidden-button')

  if (spDisplayPoint != null) {
    spDisplayButton.addEventListener('click', () => {
      spDisplayPoint.style.display = 'none'
      spHiddenPoint.style.display = 'block'
    })
  }

  if (spHiddenPoint != null) {
    spHiddenButton.addEventListener('click', () => {
      spDisplayPoint.style.display = 'block'
      spHiddenPoint.style.display = 'none'
    })
  }
}
window.addEventListener('DOMContentLoaded', spPointDisplay)

function pcPointDisplay() {
  const pcDisplayPoint = document.querySelector('#pc-display-point-section')
  const pcHiddenPoint = document.querySelector('#pc-hidden-point-section')

  const pcDisplayButton = document.querySelector('#pc-display-button')
  const pcHiddenButton = document.querySelector('#pc-hidden-button')

  if (pcDisplayPoint != null) {
    pcDisplayButton.addEventListener('click', () => {
      pcDisplayPoint.style.display = 'none'
      pcHiddenPoint.style.display = 'block'
    })
  }

  if (pcHiddenPoint != null) {
    pcHiddenButton.addEventListener('click', () => {
      pcDisplayPoint.style.display = 'block'
      pcHiddenPoint.style.display = 'none'
    })
  }
}
window.addEventListener('DOMContentLoaded', pcPointDisplay)
